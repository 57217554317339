import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TokenService } from '@services/token-service';
import { KycGuardEnum } from '@default-application-app/modules/kyc/enums/kyc-guard.enum';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { KycService } from '@services/kyc/kyc.service';
import { ProfileService } from '@services/profile/profile.service';
import { ProfileModel } from '@models/profile-model';
import { Roles } from '@enums/roles.enum';
import { AuthService } from '@services/auth/auth.service';
import { KycTierModel } from '@models/kyc/kyc-tiers.model';
import { kycLevelSignInHelper } from '@helpers/kyc-level-sign-in.helper';
import { LegalDocumentsService } from '@services/legal-documents/legal-documents.service';

@Injectable()
export class KycGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private kycService: KycService,
    private profileService: ProfileService,
    private authService: AuthService,
    private legalDocumentsService: LegalDocumentsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!this.tokenService.isAccessTokenStored()) {
        this.router.navigate(['/sign-in/business']);
      return true;
    }

    if (this.authService.isAdminOrRootUser()) {
      if (route.routeConfig.path.includes('helpdesk')) {
        return true;
      }

      this.router.navigate(['/admin/accounts']);
      return true;
    }

    return this.kycService.kycTiers().pipe(
      switchMap((kycData: KycTierModel[]) => {
        return this.profileService.loadMyProfile().pipe(map((profileData: ProfileModel) => [kycData, profileData]));
      }),
      take(1),
      map((data) => {
        const currentStep: KycGuardEnum = route.data.step;
        const kyc: KycTierModel[] = data[0] as KycTierModel[];
        const profile: ProfileModel = data[1] as ProfileModel;

        if (kycLevelSignInHelper(kyc, profile)) {
          if (currentStep === KycGuardEnum.Dashboard) {
            this.legalDocumentsService.checkAcceptingLegalDocument();
            return true;
          }

          if (this.authService.isAdminOrRootUser()) {
            this.router.navigate(['/admin/accounts']);
          } else {
            this.legalDocumentsService.checkAcceptingLegalDocument();
            this.router.navigate(['/my-profile']);
          }

          return true;
        }

        if (profile.roleName === Roles.Client) {
          if (currentStep !== KycGuardEnum.KycPersonal) {
            this.router.navigate(['/verification/personal']);
          }

          return true;
        }

        if (profile.roleName === Roles.BusinessEnterprise) {
          if (currentStep !== KycGuardEnum.KycBusiness) {
            this.router.navigate(['/verification/business']);
          }

          return true;
        }

        if (profile.roleName === Roles.BusinessCorporate) {
          if (currentStep !== KycGuardEnum.KycCorporate) {
            this.router.navigate(['/verification/business-corporate']);
          }

          return true;
        }
        if(profile.roleName === Roles.BusinessCorporate || profile.roleName === Roles.BusinessEnterprise){
          this.router.navigate(['/sign-in/business']);
          } else {
            this.router.navigate(['/sign-in/individual']);
          }
        return false;
      }),
    );
  }
}
