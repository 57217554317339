<div class="wrapper">
  <div [class.disabled]="disabled" class="drag-and-drop">
    <div class="header-cub">
      <div class="line sub-heading">
        {{ prefix + title | translate }}
        <span class="success main_color">&nbsp; {{ ' ' + ('common.drag_and_drop.browse' | translate) }}&nbsp; </span>
        {{ ' ' + ('common.drag_and_drop.to_upload' | translate)
        }}<span *ngIf="maxFilesCount > 1">{{
          'common.drag_and_drop.files_count' | translate: { maxFilesCount: maxFilesCount }
        }}</span>
      </div>

      <div *ngIf="additionalDescription" class="additional-text">
        {{ prefix + additionalDescription | translate }}
      </div>

      <div class="other-text">
        <ng-template [ngIf]="extension">
          {{ 'common.drag_and_drop.allowed_file_extensions' | translate }}: {{ extensionView }}&nbsp;
        </ng-template>
        <ng-template [ngIf]="maxSize">
          {{ 'common.drag_and_drop.maximum_file_size' | translate }}: {{ formatBytes(maxSize) }}.
        </ng-template>
      </div>
      <div *ngIf="files" class="preview">
        <div *ngFor="let item of files">
          <div *ngIf="item?.file" class="file-preview">
            <span>{{ item.file?.name }}</span>
            <span (click)="deleteFile(item)" class="remove_btn">{{ 'common.drag_and_drop.remove' | translate }}</span>
          </div>
        </div>
      </div>
      <!--      <div *ngIf="preview" class="file">-->
      <!--        <img [src]="preview" alt="" class="image"/>-->
      <!--        <div (click)="onDeleteFile.emit()" *ngIf="!disabled" class="delete-wrapper">-->
      <!--          <app-icon [iconName]="'trash'" class="icon"></app-icon>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</div>
