import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders as Headers, HttpResponse} from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringifyOptions } from 'querystring';
import {API_LINKS} from '@default-application-app/core/constants/apis';

@Injectable()
export class UserGroupTransfersApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  /**
   * Load single account
   *
   * @param {number} userId
   * @returns {Observable<any>}
   */

  public apiGetUserGroupTransfers(userId: string): Observable<any> {
    const headers = new Headers()
    return this.apiCallerService.call(
      () => this.http.get(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/get-group-transfers-of-user?user-id=${userId}` ,{
        headers,
      }),
        // this.http.get(this.configService.config.api.groupTransfers.getGroupTransfersForUser(id)),
      'apiGetUserGroupTransfers',
    );
  }

  public apiGetUserGroupTransfersById(groupId: string): Observable<any> {
    const headers = new Headers()
    return this.apiCallerService.call(
      () => this.http.get(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/get-group-transfer-by-id?group-id=${groupId}` , {headers}),
      'apiGetUserGroupTransfersById',
    );
  }

  public apiCreateGroupTransfer(userId: string) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/create-group-transfer-draft`, {
          userId: userId,
          headers,
        }),
      'apiCreateGroupTransfer',
    );
  }

  public apiUpdateGroupTransfer(groupId: string, details: {}) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/update-group-transfer-details`, {groupId: groupId, details: details}
          , {headers}),
      'apiUpdateGroupTransfer',
    );
  }

  public apiGetGroupSubTransfers(groupId: string): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.get(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/get-sub-transfers-of-group?group-id=${groupId}`, {
        headers,
      }),
      'apiGetGroupSubTransfers',
    )
  };

  public apiSubmitGroupTransfer(groupId: string, otp: string): Observable<any> {
    const headers = {};
    headers['X-OTP'] = otp;
    const body = {
      groupId: groupId,
      otpValue: otp
    };
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.submitGroupTransfer, body, { headers }),
      'apiSubmitGroupTransfer'
    );
  }

  public apiUpdateGroupTransferStatus(groupId: string, status: string): Observable<any> {
    const headers = new Headers();
    const body = {
      groupId: groupId,
      status: status
    };
    return this.apiCallerService.call(
      () => this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/update-group-transfer-status`, body, { headers }),
      'apiUpdateGroupTransferStatus'
    );
  }

  public apiUpdateGroupTotalAmount(groupId: string, totalAmount: number): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/update-group-transfer-total-amount`, {groupId, totalAmount},
        {headers}),
      'apiUpdateGroupTotalAmount',
    )
  };
  
public apiAddSubTransfer(data: any): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/create-sub-transfer`, {data},
        {headers}),
      'apiAddSubTransfers',
    )
  }

  public apiDeleteGroupTransfer(groupId: string): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/delete-group-transfer-draft`,{groupId: groupId}, {
        headers,
      }),
      'apiDeleteGroupTransfer',
    );
  }

  public apiDeleteSubGroupTransfer(subtransferId: string): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.post(`${API_LINKS.GROUP_TRANSACTIONS}/group-transfers/api/v1/private/delete-sub-group-transfer`,{subtransferId: subtransferId}, {
        headers,
      }),
      'apiDeleteSubGroupTransfer',
    );
  }
}
