import { KycTierModel } from '@models/kyc/kyc-tiers.model';
import { KycStatus } from '@enums/kyc-statuses';
import { ProfileModel } from '@models/profile-model';
import { Roles } from '@enums/roles.enum';

const isEmploymentInformationSet = (attributes, role: Roles): boolean => {
  if (!attributes?.employmentStatus || !attributes?.sourceOfFunds || !attributes?.annualIncomeFrom || !attributes?.otherNationality) {
    return false;
  }

  if (attributes?.employmentStatus === 'other' && !attributes?.employmentStatusOther) {
    return false;
  }

  if (
    (attributes?.employmentStatus === 'employed' || attributes?.employmentStatus === 'business_owner') &&
    !attributes?.employerName &&
    role === Roles.Client
  ) {
    return false;
  }

  if (attributes?.employmentStatus === 'employed' && 
  (
    !attributes?.employmentPosition ||
    !attributes?.employerCountry ||
    !attributes?.employerCity ||
    !attributes?.employerStreet ||
    !attributes?.employerBuilding ||
    !attributes?.employerFloor ||
    !attributes?.employerPhoneNumber
  )){
    return false;
  }

  if (attributes?.maritalStatus === 'married' &&
    (!attributes?.spouseFirstName ||
      !attributes?.spouseLastName
    )){
      return false;
    }

  if (!attributes?.otherNationality){
      return false;
    }
  

  if (attributes?.sourceOfFunds.includes('other') && !attributes?.sourceOfFundsOther) {
    return false;
  }


  if ((attributes?.employmentStatus === 'freelancer' || attributes?.employmentStatus === 'business_owner'
      || attributes?.employmentStatus === 'other' || attributes?.employmentStatus === 'self_employed') &&
    (!attributes?.sectorOfActivity || !attributes?.descriptionOfActivity)){
      return false;
    }

  return true;
};

export const kycLevelSignInHelper = (kyc: KycTierModel[], profile: ProfileModel): boolean => {
  const kycLevel0: KycStatus = kyc.find((item): boolean => item.level === 0).status;

  const kycLevel1: KycStatus = kyc.find((item): boolean => item.level === 1).status;

  if (
    (profile.roleName === Roles.Client || profile.roleName === Roles.BusinessEnterprise) &&
    !isEmploymentInformationSet(profile.attributes, profile.roleName)
  ) {
    return false;
  }

  return kycLevel0 === KycStatus.APPROVED && kycLevel1 === KycStatus.APPROVED;
};
