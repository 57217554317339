import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import {
  MessagingApiService,
  UPDATE_UNREAD_MESSAGES_COUNT_TIMEOUT_MS,
} from '@shared-modules/messages-api/services/messaging-api.service';
import { AutoLogoutPopupService } from '@services/auto-logout-popup.service';
import { OptionsValuesNames } from '@constants/optionsPrefixes';
import { AppOptionsService } from '@services/appOptions.service';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs';
import { DropdownItem } from '@default-application-app/modules/components/interfaces/dropdown-item';
import { EDropDownItem } from '@default-application-app/modules/components/enums';
import { RequestNotificationsService } from '@default-application-app/modules/request-notifications/services/request-notifications.service';
import { NotificationGroup } from '@default-application-app/modules/user-request-notifications/models/notification-group';
import { NotificationGroupType } from '@default-application-app/modules/request-notifications/enums/notification-group-type.enum';

export const UPDATE_NOTIFICATIONS_TIMEOUT_MS: number = 15000;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends UnsubscribeDestroyHelper implements OnInit {
  @Output() toggle = new EventEmitter<boolean>();

  @Input() isEnabled = false;

  public unreadMessages = '';

  public unreadMessagesTitle = '';

  public fullName: string;

  public showPopupConfirmAutoLogout = false;

  public popupMessage: string;

  public autoLogoutPadding: string;

  public headingMessage: string;

  public isPendingNotifications: boolean;

  public avatarLink = 'header/avatar.svg';

  public isAdmin: boolean = true;

  public dropDownMenuItems: DropdownItem[] = [
    {
      title: 'layout.system.log_out',
      key: EDropDownItem.Logout,
    },
  ];

  constructor(
    private messagingApiService: MessagingApiService,
    private autoLogoutPopupService: AutoLogoutPopupService,
    private appOptionService: AppOptionsService,
    private authService: AuthService,
    private requestNotificationsService: RequestNotificationsService,
  ) {
    super();
    this.isAdmin = this.authService.isAdminOrRootUser();
  }

  ngOnInit() {
    this.subscribeToUnreadMessagesCountUpdates();
    this.subscribeToShowPopupParams();
    this.subscribeToSiteName();
    this.subscribeToFullName();
    this.subscribeToPendingNotification();
  }

  public logout(): void {
    this.authService.logOut();
  }

  public onConfirm(confirmed: boolean) {
    this.autoLogoutPopupService.autoLogout(confirmed);
    this.showPopupConfirmAutoLogout = false;
  }

  public isRoot(): boolean {
    return this.authService.isRootUser();
  }

  public onMenuItemClick(item: DropdownItem): void {
    switch (item.key) {
      case EDropDownItem.Logout: {
        this.logout();
        break;
      }
    }
  }

  private updateUnreadMessagesCount(count: number): void {
    if (count === 0) {
      this.unreadMessages = '';
      this.unreadMessagesTitle = "You don't have new message";
    } else {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (count > 99) {
        this.unreadMessages = '99+';
        return;
      }
      if (Number.isNaN(count)) {
        this.unreadMessages = '';
        return;
      }
      this.unreadMessages = `${count}`;
      this.unreadMessagesTitle = `You have ${count} new messages`;
    }
  }

  private subscribeToShowPopupParams(): void {
    this.autoLogoutPopupService.showPopupParams
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((autoLogoutData: { status: boolean; message: string; autoLogoutPadding: string }) => {
        this.popupMessage = autoLogoutData.message;
        this.autoLogoutPadding = autoLogoutData.autoLogoutPadding;
        this.showPopupConfirmAutoLogout = autoLogoutData.status;
        this.autoLogoutPopupService.userConfirm.next(false);
      });
  }

  private subscribeToSiteName(): void {
    this.appOptionService
      .getOptionsValuePipe(OptionsValuesNames.SITE_NAME)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((title: string) => {
        this.headingMessage = `${title} Auto-logout`;
      });
  }

  private subscribeToUnreadMessagesCountUpdates() {
    this.messagingApiService.onGetUnreadCount$.pipe(takeUntil(this.unsubscribeSubject)).subscribe((count: number) => {
      this.updateUnreadMessagesCount(count);
    });

    this.messagingApiService.updateUnreadMessagesCount(true);

    interval(UPDATE_UNREAD_MESSAGES_COUNT_TIMEOUT_MS)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => this.messagingApiService.updateUnreadMessagesCount());
  }

  private subscribeToPendingNotification() {
    this.loadGroupsRequestNotifications();

    interval(UPDATE_NOTIFICATIONS_TIMEOUT_MS)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.loadGroupsRequestNotifications();
      });
  }

  private loadGroupsRequestNotifications(): void {
    this.requestNotificationsService
      .loadGroupsRequestNotifications()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((notificationsGroup: NotificationGroup[]) => {
        this.isPendingNotifications = !!notificationsGroup.find(
          (group: NotificationGroup) => group.key === NotificationGroupType.pending,
        );
      });
  }

  private subscribeToFullName() {
    this.fullName = this.authService.getFullName();

    this.authService.currentUserFullNameSubject
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((fullName: string) => {
        this.fullName = fullName;
      });
  }
}
