import { environment } from '@default-application/src/environments/environment';

const API_URIS = {
  auth: {
    me: "/users/private/v1/limited/auth/me",
    login: "/users/public/v1/auth/signin",
    signup: "/users/public/v1/auth/signup",
    generateSmsCode: "/users/private/v1/limited/generate-new-phone-code",
    generateEmailCode: "/users/private/v1/limited/generate-new-email-code",
    smsConfirmation: "/users/private/v1/limited/check-phone-code",
    emailConfirmation: "/users/private/v1/limited/check-email-code",
    generateSignatureSmsCode:
      "/users/private/v1/limited/generate-user-signature-phone-code",
    generateSignatureEmailCode:
      "/users/private/v1/limited/generate-user-signature-email-code",
    smsSignatureConfirmation: "/users/private/v1/limited/check-signature-phone-code",
    emailSignatureConfirmation: "/users/private/v1/limited/check-signature-email-code",
    confirm: "/users/public/v1/auth/confirm",
    forgotPassword: "/users/public/v1/auth/forgot-password",
    resetPassword: "/users/public/v1/auth/reset-password",
    resetPasswordById: (uid: string) =>
      `/users/private/v1/users/${uid}/reset-password`,
    changePassword: "/users/private/v1/auth/change_password",
    confirmationCode: (link: string) =>
      `/users/public/v1/auth/confirmation-code/${link}`,
    refreshToken: "/users/public/v1/auth/refresh",
    userById: (id: string) => `/users/private/v1/limited/users/profile/${id}`,
    logout: "/users/private/v1/auth/logout",
    issueTokensForUserByUid: (uid: string) =>
      `/users/private/v1/auth/root/issue-tokens-for-user-by-uid/${uid}`,
    files: {
      privateFile: "/files/private/v1/limited/private",
    },
    verification: {
      authVerification: "/users/private/v1/limited/verifications",
    },
    limited: {
      changePhoneNumber: "/users/private/v1/limited/user/phone",
      changeEmail: "/users/private/v1/limited/user/email",
      userInfoByTempToken: "/users/private/v1/limited/user",
    },
  },

  files: {
    limitedPrivateFile: "/files/private/v1/limited/private",
    limitedFilesById: (uid: string) => `/files/private/v1/limited/${uid}`,
    fileBinaryById: (id: number) => `/files/public/v1/storage/bin/${id}`,
    filePrivateBinaryById: (id: number) =>
      `/files/private/v1/storage/bin/${id}`,
  },

  delegate: {
    delegateById: (id: number) => `/users/private/v1/delegates/${id}`,
    delegates: "/users/private/v1/delegates/",
    adminDelegates: (id: string) => `/users/private/v1/delegates/${id}`,
  },

  user: {
    users: "/users/private/v1/users/",
    shortUsers: "/users/private/v1/short-users/",
    limitedProfile: "/users/private/v1/limited/auth/me",
    myProfile: "/users/private/v1/auth/me",
    unblockProfiles: "/users/private/v1/users/unblock",
    exportUserProfilesToCsv: "/users/private/v1/export/user-profiles",
    exportAdminProfilesToCsv: "/users/private/v1/export/admin-profiles",
    userNotes: (uid) => `/users/private/v1/notes/${uid}`,
    createUserNote: "/users/private/v1/notes",
    updateEmail: (id: string) => `/users/private/v1/users/${id}/email`,
    updatePhone: (id: string) => `/users/private/v1/users/${id}/phone`,
    generateSmsCode: "/users/private/v1/users/generate-new-phone-code",
    generateEmailCode: "/users/private/v1/users/generate-new-email-code",
    files: {
      myProfileFiles: "/files/private/v1/users/me",
      requestFiles: (requestId: string, category: string) => `/files/private/v1/files/admin-only/${requestId}/${category}`,
      userProfileFiles: (id: string) => `/files/private/v1/users/${id}`,
      publicFiles: (id: string) => `/files/private/v1/files/public/${id}`,
      publicFilesWithCategory: (id: string, category: string, requestId: string) => `/files/private/v1/files/public/${id}/${category}/${requestId}`,
      privateFile: (id: string) => `/files/private/v1/files/private/${id}`,
      privateFileWithcategory: (id: string, category: string, requestId: string) => `/files/private/v1/files/private/${id}/${category}/${requestId}`,
      adminOnlyFile: (id: string) => `/files/private/v1/files/admin-only/${id}`,
      adminOnlyFileWithCategory: (id: string, category: string, requestId: string) => `/files/private/v1/files/admin-only/${id}/${category}/${requestId}`,
    },
    userById: (id: string) => `/users/private/v1/users/${id}`,
    shortUserById: (id: string) => `/users/private/v1/short-users/${id}`,
    profileSettings: (id: string) => `/users/private/v1/settings/${id}`,
    extensions: {
      captchaActivity: "/users/public/v1/extensions/captcha/activity",
      captchaGenerate: "/users/public/v1/extensions/captcha/generate",
    },
  },

  userGroup: {
    groups: "/users/private/v1/user-groups",
    userGroupById: (id: number) => `/users/private/v1/user-groups/${id}`,
  },

  message: {
    unread: "/messages/private/v1/count/messages/unread",
    unassigned: "/messages/private/v1/admin/messages/unassigned",
    sendToAll: "/messages/private/v1/admin/messages/send-to-all",
    sendToSpecificGroup:
      "/messages/private/v1/admin/messages/send-to-user-group",
    sendToSpecificUsers:
      "/messages/private/v1/admin/messages/send-to-specific-users",
    messages: "/messages/private/v1/messages",
    adminUnassignedAndIncomingMessages:
      "/messages/private/v1/admin/messages/unassigned-and-incoming",
    exportCsv: {
      messages: "/messages/private/v1/csv/messages",
      adminUnassignedAndIncomingMessages:
        "/messages/private/v1/csv/admin/messages/unassigned-and-incoming",
    },
    messageById: (id: number) => `/messages/private/v1/messages/${id}`,
    deleteForMe: (id: number) => `/messages/private/v1/messages/${id}/for-me`,
    deleteForAll: (id: number) => `/messages/private/v1/messages/${id}/for-all`,
  },

  currency: {
    cardTypes: "/currencies/private/v1/card-types",
    currencies: "/currencies/private/v1/currencies",
    currenciesAdmin: "/currencies/private/v1/admin/currencies",
    mainCurrency: "/currencies/private/v1/currencies/main",
    settings: "/currencies/private/v1/admin/settings/main",
    mainCurrencyExchangeRates: "/currencies/private/v1/rates/main",
    updateRates: "/currencies/private/v1/admin/rates",
    currencyById: (id: number) =>
      `/currencies/private/v1/admin/currencies/${id}`,
  },

  rate: {
    rates: "/currencies/private/v1/rates/main",
    ratesOperations: "/currencies/private/v1/admin/rates",
    forMainCurrency: "/currencies/private/v1/rates/pair",
  },

  account: {
    accounts: "/accounts/private/v1/accounts",
    adminAccounts: "/accounts/private/v1/admin/accounts",
    accountById: (id: number) => `/accounts/private/v1/accounts/${id}`,
    accountTypes: "/accounts/private/v1/account-types",
    accountTypeById: (id: number) => `/accounts/private/v1/account-types/${id}`,
    cards: "/accounts/private/v1/own-cards",
    adminCards: "/accounts/private/v1/cards",
    userCards: "/accounts/private/v1/own-cards",
    cardById: (id: number) => `/accounts/private/v1/cards/${id}`,
    cardTypes: "/accounts/private/v1/card-types",
    cardTypeById: (id: number) => `/accounts/private/v1/card-types/${id}`,
    deleteCardType: (id: number) => `/accounts/private/v1/card-types/${id}`,
    generateAccountNumber: "/accounts/private/v1/generate/accounts/number",
    paymentMethods: "/accounts/private/v1/payment-methods",
    paymentPeriods: "/accounts/private/v1/payment-periods",
    revenueAccounts: "/accounts/private/v1/revenue-accounts",
    iwtBankDetails: "/accounts/private/v1/iwt-bank-accounts",
    importAccountsFromCsv: "/accounts/private/v1/import/accounts",
    importCardsFromCsv: "/accounts/private/v1/import/cards",
    updateTransfersFromCsv: "/accounts/private/v1/admin/requests/csv/update",
    importTransfersFromCsv: "/accounts/private/v1/admin/requests/csv/import",
    exportAccountsToCsv: "/accounts/private/v1/admin/export/accounts",
    exportCardsToCsv: "/accounts/private/v1/admin/export/cards",
    exportRevenueAccountsToCsv: "/accounts/private/v1/admin/export/cards",
    exportTransferRequestsToCsv:
      "/accounts/private/v1/admin/export/transfer-requests",

    requests: "/accounts/private/v1/requests",
    tbaRequestPreview: "/accounts/private/v1/tba-requests/preview",
    tbaRequest: "/accounts/private/v1/tba-requests",
    tbuRequestPreview: "/accounts/private/v1/tbu-requests/preview",
    tbuRequest: "/accounts/private/v1/tbu-requests",
    owtRequestPreview: "/accounts/private/v1/owt-requests/preview",
    owtRequest: "/accounts/private/v1/owt-requests",
    caRequestPreview: "/accounts/private/v1/admin/ca-requests/preview",
    caRequest: "/accounts/private/v1/admin/ca-requests",
    daRequestPreview: "/accounts/private/v1/admin/da-requests/preview",
    daRequest: "/accounts/private/v1/admin/da-requests",
    cftRequestPreview: "/accounts/private/v1/cft-requests/preview",
    cftRequest: "/accounts/private/v1/cft-requests",
    tanSettings: "/accounts/private/v1/settings",
    draRequest: "/accounts/private/v1/admin/dra-requests",
    myTansCount: "/accounts/private/v1/user/tan/count",
    tanExtensionActivityStatus:
      "/accounts/private/v1/admin/tan/tan-by-sms/activity",
    requestTan: "/accounts/private/v1/user/tan",
    requestTanAvailability:
      "/accounts/private/v1/user/tan/request/availability",
    tansCountByUserId: (id: string) =>
      `/accounts/private/v1/admin/tan/count/${id}`,
    generateTansByUserId: (id: string) =>
      `/accounts/private/v1/admin/tan/${id}`,
    urlUserTemplates: (part: string | number) =>
      `/accounts/private/v1/user/templates/${part}`,
    tanSettingForTransfer: (settingVal: string) =>
      `/accounts/private/v1/settings/${settingVal}`,
    tbaRequestPreviewAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/tba-requests/preview/user/${userId}`,
    tbaRequestAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/tba-requests/user/${userId}`,
    tbuRequestPreviewAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/tbu-requests/preview/user/${userId}`,
    tbuRequestAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/tbu-requests/user/${userId}`,
    tbuExecute: (id: string): string =>
      `/accounts/private/v1/tbu-requests/execute/${id}`,
    tbuCancel: (id: string): string =>
      `/accounts/private/v1/tbu-requests/cancel/${id}`,
    owtRequestPreviewAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/owt-requests/preview/user/${userId}`,
    owtRequestAsAdmin: (userId: string) =>
      `/accounts/private/v1/admin/owt-requests/user/${userId}`,
    updateRequestById: (id: string) =>
      `/accounts/private/v1/admin/requests/${id}`,
    revenueAccountById: (id: number) =>
      `/accounts/private/v1/revenue-accounts/${id}`,
    deductFromRevenueAccountById: (id: number) =>
      `/accounts/private/v1/revenue-accounts/${id}/deduct`,
    getRequest: (id: string) => `/accounts/private/v1/requests/${id}`,
    executeRequest: (id: string) =>
      `/accounts/private/v1/admin/requests/execute/${id}`,
    cancelRequest: (id: string) =>
      `/accounts/private/v1/admin/requests/cancel/${id}`,
    iwtBankDetailsSingleById: (id: number) =>
      `/accounts/private/v1/iwt-bank-accounts/${id}`,
    iwtBankDetailsByAccountId: (id: number) =>
      `/accounts/private/v1/iwt-bank-accounts/${id}/by-account-id`,
    sendSmsToPhone: "/accounts/private/v1/otp/send-to-phone",
    sendSmsToEmail: "/accounts/private/v1/otp/send-to-email",

    transactions: {
      userRequests: "/accounts/private/v1/user/requests",
      userList: "/accounts/private/v1/user/transactions",
      history: "/accounts/private/v1/user/transactions-history",
      userInfo: "/accounts/private/v1/user/info/transactions",
      transactionById: (id: string) =>
        `/accounts/private/v1/user/transactions/${id}`,
      exportCsv: "/accounts/private/v1/user/export/transactions-report",
    },

    subtransactions: {
      subtransactionsByGroupId: (id: string) =>
        `/accounts/private/v1/user/sub-transactions/${id}`
    },

    countries: {
      list: "/accounts/public/v1/countries",
      countryById: (id: string) => `/accounts/public/v1/countries/${id}`,
    },
  },

  privacy: {
    documents: "/privacy/private/v1/documents",
    document: (id: string) => `/privacy/private/v1/documents/${id}`,
    effectiveAcceptances: `/privacy/private/v1/effective-acceptances`,
  },

  file: {
    files: "/files/private/v1/files",
    uploadPrivate: "/files/private/v1/files/public",
    uploadAdminOnly: "/files/private/v1/files/admin-only",
    uploadPublic: (id: string) => `/files/private/v1/files/public/${id}`,
    fileById: (id: number) => `/files/private/v1/files/${id}`,
    fileBinaryById: (id: number) => `/files/public/v1/storage/bin/${id}`,
    filePrivateBinaryById: (id: number) =>
      `/files/private/v1/storage/bin/${id}`,
  },

  reports: {
    getSAS: "/reports/private/v1/account", // getSAS: '/reports/private/v1/account?includeAdditionalEntities=1',
    getAllAccTransactions: "/reports/private/v1/transaction",
    getUserAllAccBalance:
      "/reports/private/v1/balance?includeAdditionalEntities=1",
    exportUserAllAccountsBalancesToCsv: "/reports/private/v1/balance/export",
    exportUserAllAccountsTransactionsToCsv:
      "/reports/private/v1/transaction/export",
    exportUserSpecificAccountStatementToCsv:
      "/reports/private/v1/account/export",
  },

  systemLog: {
    loadLogList: "/logs/private/v1/admin/system-logs?include=user",
    exportInformationLogsToCsv: "/logs/private/v1/admin/export/information-log",
    exportTransactionLogsToCsv:
      "/logs/private/v1/admin/export/transactions-log",
    loadLogView: (id: number) =>
      `/logs/private/v1/admin/system-logs/${id}?include=user`,
    accessLog: "/reports/private/v1/admin/system/access",
    accessLogCsv: "/reports/private/v1/admin/system/access/export",
  },

  scheduledTransaction: {
    loadList:
      "/accounts/private/v1/scheduled-transactions?include=account,account.type",
    exportToCsv:
      "/accounts/private/v1/admin/export/scheduled-transactions?include=account,account.type",
    getById: (id: number) =>
      `/accounts/private/v1/scheduled-transactions/${id}`,
  },

  permission: {
    admin: {
      groups: "/permissions/private/v1/admin/group?scope=admin",
      clientGroups: "/permissions/private/v1/admin/group?scope=client",
      permissionGroupActions: "/permissions/private/v1/admin/action",
      permissionGroupById: (id: number) =>
        `/permissions/private/v1/admin/group/${id}`,
      category: "/permissions/private/v1/admin/category",
    },
    permissionsByUserId: "/permissions/private/v1/admin/permission",
  },

  blockedIpAddresses: {
    getAll: "/users/private/v1/blocked-ips",
    unblock: "/users/private/v1/blocked-ips/unblock",
  },

  fee: {
    create: "/accounts/private/v1/admin/fee/transfer",
    forSubject: (subject: string) =>
      `/accounts/private/v1/admin/fee/transfer/subject/${subject}`,
    feeById: (id: number) => `/accounts/private/v1/admin/fee/transfer/id/${id}`,
    feeParametersById: (id: number) =>
      `/accounts/private/v1/admin/fee/transfer/parameters/${id}`,
    userFeeType: (subject: string) =>
      `/accounts/private/v1/user/fee/transfer/subject/${subject}`,
  },

  groupTransfers: {
    getGroupTransfersForUser: (userId: string) => `/group-transfers/api/v1/private/get-group-transfers-of-user?user-id=${userId}`,
    getUserGroupTransfersById: (groupId: string) => `/group-transfers/api/v1/private/get-group-transfer-by-id?group-id=${groupId}`,
    createGroupTransfer: "/group-transfers/api/v1/private/create-group-transfer-draft",
    updateGroupTransfer: "/group-transfers/api/v1/private/update-group-transfer-details",
    getGroupSubTransfers: (groupId: string) => `/group-transfers/api/v1/private/get-sub-transfers-of-group?group-id=${groupId}`,
    updateGroupTransferStatus: "/group-transfers/api/v1/private/update-group-transfer-status",
    updateGroupTotalAmount: "/group-transfers/api/v1/private/update-group-transfer-total-amount",
    addSubTransfer: "/group-transfers/api/v1/private/create-sub-transfer",
    deleteGroupTransfer: "/group-transfers/api/v1/private/delete-group-transfer-draft",
    deleteSubGroupTransfer: "/group-transfers/api/v1/private/delete-sub-group-transfer",
    submitGroupTransfer: "/accounts/private/v1/group-tbu-requests"
  },

  options: {
    update: "/settings/private/v1/config",
    load: (path: string) => `/settings/private/v1/config/${path}`,
    loadPublic: (path: string) => `/settings/public/v1/config/${path}`,
  },

  language: {
    update: "/users/private/v1/auth/me/language",
    load: "/users/public/v1/languages",
  },

  systemEmail: {
    commonSettings: "/notifications/private/v1/settings",
    testEmail: "/notifications/private/v1/test/smtp",
    emailTokens: "/notifications/private/v1/settings/tokens",
    templateSettings: "/notifications/private/v1/templates",
    templateSettingsLoad: (path: string) =>
      `/notifications/private/v1/templates/${path}`,
  },

  systemPushNotifications: {
    sendTestPushNotification: "/notifications/private/v1/test/push",
    templateEvents: "/notifications/private/v1/push/events",
    templateUsers: "/users/private/v1/short-users",
    templateDevices: (uid: string) =>
      `/notifications/private/v1/push-tokens/user/${uid}`,
  },

  systemSms: {
    testSms: "/notifications/private/v1/test/sms",
  },

  kyc: {
    getListRequests: "/kyc/private/v1/admin/requests",
    updateRequestStatus: (requestId: string) =>
      `/kyc/private/v1/admin/request/${requestId}/update-status`,
    updateRequirementStatus: (requirementId: string, userId: string) =>
      `/kyc/private/v1/admin/requirement/${requirementId}/user/${userId}/update-status`,

    updateRequirementByAdmin: (requirementId: string, userId: string) =>
      `/kyc/private/v1/admin/requirement/${requirementId}/user/${userId}`,
    getListTiers: (userId: string) =>
      `/kyc/private/v1/admin/tiers/user/${userId}`,

    getTierById: (tierId: string): string =>
      `/kyc/private/v1/user/tiers/${tierId}`,
    createRequest: "/kyc/private/v1/requests",
    deleteRequestById: (id: string) => `/kyc/private/v1/requests/${id}`,
    getCurrentTier: "/kyc/private/v1/tiers/current",

    getCountriesList: "/kyc/private/v1/admin/countries",
    getTiersByCountryCode: (code: string) =>
      `/kyc/private/v1/admin/country/${code}/tiers`,
    tierSetting: (tierId: string) => `/kyc/private/v1/admin/tier/${tierId}`,
    initiate: "/jumio/private/v1/initiate/id",
    employment: (id: string) =>
      `/users/private/v1/limited/user/${id}/employment`,
    tiers: "/kyc/private/v1/user/tiers",
    currentTier: "/kyc/private/v1/user/current-tier",
    updateKyc: (id: string) => `/kyc/private/v1/user/requirements/${id}`,
    legalEntity: `/kyc/private/v1/user/legal-entity`,
    legalEntityById: (id: number) => `/kyc/private/v1/user/legal-entity/${id}`,
    legalEntityForAdminById: (id: string) =>
      `/kyc/private/v1/admin/legal-entity/${id}`,
    legalEntityFromAdminForUser: `/kyc/private/v1/admin/legal-entity/`,
    updateLevel: "/kyc/private/v1/user/requests",
  },

  legalDocuments: {
    tac: (uid: string) => `/users/private/v1/users/${uid}/consent-tac`,
    privacyPolicy: (uid: string) =>
      `/users/private/v1/users/${uid}/consent-privacy-policy`,
  },

  adminReports: {
    allTransaction: "/reports/private/v1/admin/user/transaction",
    allTransactionCsv: "/reports/private/v1/admin/user/transaction/export",
    balance: "/reports/private/v1/admin/user/balance",
    balanceCsv: "/reports/private/v1/admin/user/balance/export",
    adminTransaction: "/reports/private/v1/admin/system/transaction",
    adminTransactionCsv: "/reports/private/v1/admin/system/transaction/export",
    adminBalance: "/reports/private/v1/admin/system/balance",
    adminBalanceCsv: "/reports/private/v1/admin/system/balance/export",
    maturityDates: "/reports/private/v1/admin/system/maturity",
    maturityDatesCsv: "/reports/private/v1/admin/system/maturity/export",
    account: "/reports/private/v1/admin/user/account",
    accountCsv: "/reports/private/v1/admin/user/account/export",
    owtTransaction: "/reports/private/v1/admin/system/outgoing-transfer",
    owtTransactionCsv:
      "/reports/private/v1/admin/system/outgoing-transfer/export",
    card: "/reports/private/v1/admin/system/card",
    cardCsv: "/reports/private/v1/admin/system/card/export",
    revenue: "/reports/private/v1/admin/system/revenue",
    revenueCsv: "/reports/private/v1/admin/system/revenue/export",
    accessLog: "/reports/private/v1/admin/system/access",
    accessLogCsv: "/reports/private/v1/admin/system/access/export",
    manualTransaction: "/reports/private/v1/admin/system/manual-transaction",
    manualTransactionCsv:
      "/reports/private/v1/admin/system/manual-transaction/export",
    interest: "/reports/private/v1/admin/system/interests",
    interestCsv: "/reports/private/v1/admin/system/interests/export",
    overview: "/reports/private/v1/admin/system/overview",
    overviewCsv: "/reports/private/v1/admin/system/overview/export",
  },

  news: {
    allNews: "/news/private/v1/article",
    singleNews: (id: number) => `/news/private/v1/article/${id}`,
  },

  customization: {
    logoLink: "/assets/images/logo/logo-full.svg",
  },

  notifications: {
    plivoAccountDetails: "/notifications/private/v1/notifiers/plivo/details",
    mySettings: "/notifications/private/v1/user-settings",
    userSettings: (uid: string): string =>
      `/notifications/private/v1/user-settings/${uid}`,
  },

  verification: {
    createVerification: "/users/private/v1/verifications",
    userVerification: (id: string) =>
      `/users/private/v1/verifications/list/${id}`,
    approveVerification: (id: number) =>
      `/users/private/v1/verifications/approve/${id}`,
    cancelVerification: (id: number) =>
      `/users/private/v1/verifications/cancel/${id}`,

    status: (userId: string): string =>
      `/verification/private/v1/admin/user/${userId}/status`,
    verificationImage: (path: string, requestId: number): string =>
      `/verification/private/v1/admin/request/${requestId}/${path}-image`,
    exportCsv: (path: string, userId: string): string =>
      `/verification/private/v1/admin/user/${userId}/${path}/csv`,
    sendValidationLink: "/verification/private/v1/admin/generate-verification",
    usersStatus: "/verification/private/v1/admin/users-status",
    requests: (userId: string): string =>
      `/verification/private/v1/admin/user/${userId}/requests`,
  },

  extensions: {
    extensionsList: "/extensions/private/v1/extensions",
    extension: (serviceName: string) =>
      `/extensions/private/v1/extensions/${serviceName}`,
    extensionsSettingsList: (serviceName: string) =>
      `/extensions/private/v1/extensions/${serviceName}/settings/elements`,
    extensionsSettings: (serviceName: string) =>
      `/extensions/private/v1/extensions/${serviceName}/settings`,
    getExtensionsActivityStatus: (url: string) => url,
    accountNumberLength: {
      getValidatorData: "/accounts/private/v1/form/account/post",
    },
    removeBranding: "/settings/public/v1/extensions/remove_branding",
  },

  asynchronous: {
    jobs: (jobsId: string) => `/users/public/v1/jobs/${jobsId}`,
  },

  invoices: {
    sampleFile: "/accounts/private/v1/download/sample-file",
    listInvoices: "/accounts/private/v1/invoice",
    massChangeStatus: "/accounts/private/v1/invoice/mass-change-status",
    invoice: (id: number) => `/accounts/private/v1/invoice/${id}`,
    change_status: (id: number) =>
      `/accounts/private/v1/invoice/${id}/change-status`,
    files: {
      uploadInvoice: "/accounts/private/v1/invoice/upload",
    },
  },

  externalApis: {
    currentCountry: "http://ip-api.com/json",
  },

  merchantInvoices: {
    createInvoice: "/accounts/private/v1/merchant-invoices",
    senderAccountInfo: (id: string) =>
      `/accounts/private/v1/merchant-invoices/account/${id}`,
    invoicePreview: (id: string) =>
      `/accounts/private/v1/merchant-invoices/preview/${id}`,
    issueRefund: (id: string) =>
      `/accounts/private/v1/merchant-invoices/refund/${id}/issue`,
    refundInfo: (id: string) =>
      `/accounts/private/v1/merchant-invoices/refund/${id}`,
    approveRefund: (id: string) =>
      `/accounts/private/v1/merchant-invoices/refund/${id}/approve`,
    declineRefund: (id: string) =>
      `/accounts/private/v1/merchant-invoices/refund/${id}/decline`,
  },

  cash: {
    evaluateCashIn: "/accounts/private/v1/cash-requests/ci-requests/preview",
    evaluateCashInAdmin: (id: string): string =>
      `/accounts/private/v1/admin/cash-requests/ci-requests/preview/user/${id}`,
    evaluateCashOut: "/accounts/private/v1/cash-requests/co-requests/preview",
    evaluateCashOutAdmin: (id: string): string =>
      `/accounts/private/v1/admin/cash-requests/co-requests/preview/user/${id}`,
    cashIn: "/accounts/private/v1/cash-requests/ci-requests",
    cashOut: "/accounts/private/v1/cash-requests/co-requests",
    cashInAdmin: (id: string): string =>
      `/accounts/private/v1/admin/cash-requests/ci-requests/user/${id}`,
    cashOutAdmin: (id: string): string =>
      `/accounts/private/v1/admin/cash-requests/co-requests/user/${id}`,
    cancelRequest: (referenceNumber: string): string =>
      `/accounts/private/v1/cash-requests/cancel/${referenceNumber}`,
    cashAgents: `/users/private/v1/cash-agents`,
  },

  requestNotifications: {
    notifications: "/accounts/private/v1/request-notifications",
  },

  cashSource: {
    submitComplianceRequest: "/cash-source/api/v1/private/submit-compliance-request",
    getComplianceRequests: "/cash-source/api/v1/private/get-compliance-requests",
    approveComplianceRequest: "/cash-source/api/v1/private/approve-compliance-request",
    rejectComplianceRequest: "/cash-source/api/v1/private/reject-compliance-request"
  },

  limits: {
    getLimitsForUser: (userId: string, currencyType: string): string =>
      `/limits/api/v1/private/get-limits-of-user-by-currency-type?user-id=${userId}&currency-type=${currencyType}`
  }
};

const PAGES = {
  // placeholder
  placeholder: '/placeholder-page',

  // auth
  login: '/sign-in',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  signup: '/sign-up',
  completeRegistration: '/complete-registration',

  // messages
  messagingHistory: '/messages/messaging-history',
  newMessage: '/messages/new-message',
  conversation: '/messages/conversation',
};

const LIMITS = {
  messages: 10,
};

const PATTERNS = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const APPLICATION_CONFIG = {
  endpoint: environment.apiHost,
  api: API_URIS,
  pages: PAGES,
  limits: LIMITS,
  patterns: PATTERNS,
};
