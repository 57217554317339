import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VALIDATION_PROPERTY } from '@constants/validation-property';
import { AccountInterface } from '@interfaces/account-interface';
import { AccountService } from '@services/account/account.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserGroupTransfersService } from '../../services/user-group-transfers.services';
import { MatDialog } from '@angular/material/dialog';
import { AddSubbTransferComponent } from '../../components/add-sub-transfer.component';



@Component({
  selector: 'app-create-group-transfer',
  templateUrl: './create-group-transfer.component.html',
  styleUrls: ['./create-group-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CreateGroupTransfer  implements OnInit, OnDestroy  {

  public groupTransferForm: FormGroup
  public accounts: AccountInterface[] = [];
  protected unsubscribeSubject = new Subject();
  public subTransfers: any[] = [];
  public groupDetails: any
  public selectedAccount: AccountInterface;
  public groupId: string;

  

  constructor(
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private UserGroupTransfersService: UserGroupTransfersService,
    protected accountService: AccountService,
    private router: Router,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {
    this.createForm();
    this.getGroupTransferById()
    this.getAccounts();
    this.getGroupSubTransfers();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.groupId = params['groupId'];
      if (this.groupId) {
        this.loadGroupSubTransferDetails(this.groupId);
      }
    });
  }

  loadGroupSubTransferDetails(groupId: string) {
    this.getGroupSubTransfers();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  createForm() {
    this.groupTransferForm = this.fb.group({
      groupTransferTitle: [null, Validators.required],
      accountFrom: [null, Validators.required],
      description: ['', Validators.maxLength(VALIDATION_PROPERTY.TRANSFER_DESCRIPTIONS_LENGTH)],
      numberOfSubtransfers: [null],
      currency: [null],
    });
  }


  
  public getAccounts(): void {
    this.accountService
      .loadAccounts()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(
        (accounts: AccountInterface[]) => {
          this.accounts = accounts;
          this.groupTransferForm.patchValue({accountFrom: accounts.find(acc => acc.id === this.groupDetails.sender_account_id)})
        }
      );
  }

  public updateGroupTransferDetails(): void {
    if (this.groupTransferForm.valid) {
      this.UserGroupTransfersService.updateGroupTransfer(this.activatedRoute.snapshot.queryParamMap.get('groupId'), this.groupTransferForm.value)
      
    }
    
  } 

  public getGroupTransferById(): void {
    this.UserGroupTransfersService
    .getUserGroupTransfersById(this.activatedRoute.snapshot.queryParamMap.get('groupId'))
    .subscribe((response: any) => {
      this.groupDetails = response.data.groupTransfer
      this.groupTransferForm.patchValue({groupTransferTitle: this.groupDetails.title})
      this.groupTransferForm.patchValue({description: this.groupDetails.description})
    })
  }

  public openAddSubTransferDialog(): void {
    if (!this.groupTransferForm.invalid){
    this.dialog.open(AddSubbTransferComponent, {
      data: {
        accountFrom: this.groupTransferForm.get('accountFrom').value,
      },
    }).afterClosed()
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe(() => {
       this.getGroupSubTransfers();
    });;

  }
  }

  public openEditSubTransferDialog(subtransferId: string): void {
    if (!this.groupTransferForm.invalid){
    this.dialog.open(AddSubbTransferComponent, {
      data: {
        accountFrom: this.groupTransferForm.get('accountFrom').value,
      },
    }).afterClosed()
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe(() => {
       this.getGroupSubTransfers();
    });;

  }
  }

  public handleBlur (event: any): void {
    if (this.groupDetails && this.groupDetails.status === "new") {
   this.updateGroupTransferDetails();
    }
  }

  public onSave (): void {
    this.updateGroupTransferDetails()
    this.getGroupTransferById();
    this.router.navigate([`group-transfers/table`]); 
  }
  public onCancel (): void {
    this.router.navigate([`group-transfers/table`]);
  }

  public getGroupSubTransfers(): void {
    this.UserGroupTransfersService.getGroupSubTransfers(this.activatedRoute.snapshot.queryParamMap.get('groupId'))
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe(subtransfers => {
      this.subTransfers = [...subtransfers.data.subtransfers];
      this.groupTransferForm.patchValue({numberOfSubtransfers: this.subTransfers.length})
      this.UserGroupTransfersService.updateGroupTotalAmount(this.activatedRoute.snapshot.queryParamMap.get('groupId'), this.getTotalAmount());
    this.changeDetector.detectChanges();
    });
    
}



public getTotalAmount(): number {
  return this.subTransfers.reduce((sum, subtransfer) => sum + subtransfer.amount, 0);
}

public redirectToSummaryPage(): void {
  this.updateGroupTransferDetails()
  this.router.navigate([`group-transfers/summary`], {queryParams: {groupId: this.activatedRoute.snapshot.queryParamMap.get('groupId')}}); 
}

public isSubmitDisabled(): boolean {
  return this.subTransfers.length < 2
}

public isAccountChangeDisabled(): boolean {
  return this.subTransfers.length > 0
}

public getCurrency(): string {
  const currencyCode = this.groupTransferForm.get("accountFrom")?.value?.type?.currencyCode;
  return currencyCode === 'USD' ? '$' : 'LBP';
}

public deleteSubGroupTransfer(subtransferId: string): void {
  this.UserGroupTransfersService.deleteSubGroupTransfer(subtransferId)
  .subscribe(response => {
    this.getGroupSubTransfers();
  });
}

}
