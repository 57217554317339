/**
 * @license Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@interfaces/callResponce.interface';

@Injectable()
export class ProfileSettingsFileApiService {
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  public apiLoadProfileFilesList(userId: string, params: any): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.user.files.userProfileFiles(userId), {
          params,
        }),
      'apiLoadProfileFiles',
    );
  }

  public apiLoadMyProfileFilesList(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.user.files.myProfileFiles),
      'apiLoadProfileFiles',
    );
  }

  public apiUploadFileToProfile(
    userId: string,
    formData: FormData,
    options: { isAdminOnly?: boolean; isPrivate?: boolean },
  ): Observable<CallResponceInterface> {
    let url: string;
    if (options.isAdminOnly) {
      url = this.configService.config.api.user.files.adminOnlyFile(userId);
    } else if (options.isPrivate) {
      url = this.configService.config.api.user.files.privateFile(userId);
    } else {
      url = this.configService.config.api.user.files.publicFiles(userId);
    }
    return this.apiCallerService.call(() => this.http.post(url, formData), `apiUploadFileToProfile_${userId}`);
  }

  public apiDeleteFileFromProfile(fileId: number): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.delete(this.configService.config.api.file.fileById(fileId)),
      'apiDeleteFileFromProfile',
    );
  }
}
