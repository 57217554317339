import { Injectable } from '@angular/core';
import { AuthApiService } from '@default-application-app/core/services/auth/auth-api.service';
import { Observable, of, Subject } from 'rxjs';
import { TokenService } from '@default-application-app/core/services/token-service';
import { TokenInterface } from '@default-application-app/core/interfaces/token-interface';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CallResponceInterface } from '@default-application-app/core/interfaces/callResponce.interface';
import { ApiError } from '@default-application-app/core/models/api-error.model';
import { catchError, exhaustMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
// eslint-disable-next-line import/no-duplicates
import * as FromLogout from '@default-application/src/app/reducers/app.reducer';
// eslint-disable-next-line import/no-duplicates
import * as FromApp from '@default-application/src/app/reducers/app.reducer';
import * as LogoutActions from '@default-application/src/app/actions/logout.actions';
import * as UserPermissionsActions from '@default-application/src/app/actions/permission.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ErrorHandlerService } from '@default-application-app/core/services/error-handler.service';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import {
  ChangePasswordInterface,
  ResetPasswordInterface,
} from '@default-application-app/core/interfaces/set-password.interface';
import { ProfileModel } from '@default-application-app/core/models/profile-model';
import { Verification } from '@default-application-app/core/constants/verification';
import { VerificationModel } from '@default-application-app/core/models/verification-model';
import { CountryFromApi } from '@default-application-app/core/interfaces/country.interface';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid/lib/device-uuid.min';
import * as LanguagesActions from '@default-application/src/app/actions/siteLanguage.actions';
// eslint-disable-next-line import/no-duplicates
import * as FromLanguage from '@default-application/src/app/reducers/app.reducer';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { LimitedUserInfoInterface } from '@interfaces/limited-user-info.interface';
import { ErrorCodes } from '@constants/errorCodes';
import { Roles } from '@enums/roles.enum';
import { ProfileService } from '@services/profile/profile.service';

@Injectable()
export class AuthService {
  public currentUserFullNameSubject: Subject<string> = new Subject<string>();

  public get sessionId() {
    return this._sessionId;
  }

  public get deviceId() {
    return this._deviceId;
  }

  private errorCodes = ErrorCodes;

  private currentUserFullName: string;

  private tempAuthToken: string;

  private tempUserId: string;

  private signOutSubject: Subject<boolean> = new Subject<boolean>();

  private _sessionId: string;

  private _deviceId: DeviceUUID;

  constructor(
    private authApiService: AuthApiService,
    private tokenService: TokenService,
    private helper: JwtHelperService,
    private router: Router,
    private store: Store<FromLogout.LogoutState>,
    private permissionsStore: Store<FromApp.UserPermissionsState>,
    private languagesStore: Store<FromLanguage.SiteLanguageState>,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private notificationsService: NotificationsServiceWithTranslate,
    private appOptionService: AppOptionsService,
  ) {
    this.subscribeToSignOut();
  }

  public hasTempAuthToken(): boolean {
    return !!this.tempAuthToken;
  }

  public getTempAuthToken(): string {
    return this.tempAuthToken;
  }

  public setTempAuthToken(token: string): void {
    this.tempAuthToken = token;
  }

  public signIn(data): Observable<{ success: boolean; data?: any }> {
    return this.authApiService.apiSignIn(data).pipe(
      map((res: CallResponceInterface) => {
        if (res.error) {
          const unconfirmedProfileDataError = (res.data as []).find(
            (el: any) => el.code === this.errorCodes.UNCONFIRMED_PROFILE_DATA
          );
          if (unconfirmedProfileDataError) {
            const { temporaryAccessToken } = (res.data as []).find(
              (el: any) => el.temporaryAccessToken
            );
            if (temporaryAccessToken) {
              this.tokenService.saveToken({
                accessToken: "",
                refreshToken: "",
                temporaryAccessToken,
              });
            }
          }

          return { success: false, data: <ApiError[]>res.data };
        }
        const isSaved = this.saveToken(res);
        if (isSaved) {
          this.store.dispatch(new LogoutActions.LogIn());
          this.store.dispatch(new LanguagesActions.LoadUserLanguage());
          this.notificationsService.remove();
          this.tempAuthToken = null;
          this.tempUserId = null;
          if (this.isAdminOrRootUser()) {
            this.permissionsStore.dispatch(
              new UserPermissionsActions.LoadUserPermissions()
            );
          }
        }
        return { success: isSaved, data: res.data };
      })
    );
  }

  public rootSignInAsUser(
    uid: string
  ): Observable<{ success: boolean; data?: any }> {
    return this.authApiService.apiRootSignInAsUser(uid).pipe(
      map((res: CallResponceInterface) => {
        if (res.error) {
          return { success: false, data: <ApiError[]>res.data };
        }
        const isSaved = this.saveToken(res);
        if (isSaved) {
          this.store.dispatch(new LogoutActions.LogIn());
          this.notificationsService.remove();
          this.tempAuthToken = null;
          this.tempUserId = null;
          if (this.isAdminOrRootUser()) {
            this.permissionsStore.dispatch(
              new UserPermissionsActions.LoadUserPermissions()
            );
          }
        }
        return { success: isSaved, data: res.data };
      })
    );
  }

  public signUp(
    signUpData
  ): Observable<{ data: any | ApiError[]; error: boolean }> {
    return this.languagesStore.pipe(
      select(FromLanguage.getSiteLanguage),
      switchMap((language: string) =>
        this.authApiService.apiSignUp({ ...signUpData, language })
      ),
      map((response: HttpResponse<{ messages: string[]; data: any }>) => {
        this.tokenService.saveTempAuthToken(
          response?.body?.data?.accessToken ||
            response?.body?.data?.temporaryAccessToken
        );
        return { data: response?.body?.data || {}, error: false };
      }),
      catchError((error: HttpErrorResponse) => {
        const errors: ApiError[] =
          error && error.error && error.error.errors
            ? ErrorHandlerService.generateApiErrors(error.error.errors)
            : [];
        return of({ data: errors || {}, error: true });
      })
    );
  }

  public generateSmsCode(): Observable<CallResponceInterface> {
    this.spinner.show();
    return this.authApiService
      .apiGenerateSmsCode()
      .pipe(tap(() => this.spinner.hide()));
  }

  public generateSignatureSmsCode(): Observable<CallResponceInterface> {
    this.spinner.show();
    return this.authApiService
      .apiGenerateSignatureSmsCode()
      .pipe(tap(() => this.spinner.hide()));
  }

  public generateEmailCode(): Observable<CallResponceInterface> {
    this.spinner.show();
    return this.authApiService
      .apiGenerateEmailCode()
      .pipe(tap(() => this.spinner.hide()));
  }

  public generateSignatureEmailCode(): Observable<CallResponceInterface> {
    this.spinner.show();
    return this.authApiService
      .apiGenerateSignatureEmailCode()
      .pipe(tap(() => this.spinner.hide()));
  }

  public confirmSMSNumber(code: string): Observable<CallResponceInterface> {
    return this.authApiService.apiSMSConfirmation(code);
  }

  public confirmEmailCode(code: string): Observable<CallResponceInterface> {
    return this.authApiService.apiEmailConfirmation(code);
  }

  public confirmSignatureSMSNumber(code: string): Observable<CallResponceInterface> {
    return this.authApiService.apiSMSSignatureConfirmation(code);
  }

  public confirmSignatureEmailCode(code: string): Observable<CallResponceInterface> {
    return this.authApiService.apiEmailSignatureConfirmation(code);
  }

  public updateUnconfirmedPhoneNumber(
    phoneNumber: string
  ): Observable<CallResponceInterface> {
    return this.authApiService.apiUpdateUnconfirmedPhoneNumber(phoneNumber);
  }

  public updateUnconfirmedEmail(
    email: string
  ): Observable<CallResponceInterface> {
    return this.authApiService.apiUpdateUnconfirmedEmail(email);
  }

  public updateUserSignUp(
    uid: string,
    profile: any
  ): Observable<CallResponceInterface> {
    return this.authApiService.apiPutUser(uid, profile);
  }

  public verificationLimitedUserSignUp(
    fileId: string,
    verificationType: Verification
  ): Observable<CallResponceInterface> {
    return this.authApiService.verificationLimitedFileUser(
      fileId,
      verificationType
    );
  }

  public listVerificationUserSignUp(): Observable<VerificationModel[]> {
    return this.authApiService.listVerificationFileUser();
  }

  public recoverPassword({ email }): Observable<CallResponceInterface> {
    return this.authApiService.apiRecoverPassword(email);
  }

  public resetPassword(data: {
    code: string;
    password: string;
  }): Observable<CallResponceInterface> {
    return this.authApiService.apiResetPassword(data);
  }

  public resetPasswordById(
    data: ResetPasswordInterface
  ): Observable<CallResponceInterface> {
    this.spinner.show();

    return this.authApiService.apiResetPasswordById(data).pipe(
      tap(({ error }: CallResponceInterface) => {
        this.spinner.hide();
        if (!error) {
          this.notificationsService.success(
            "common.notifications",
            "password",
            "was_successfully_changed"
          );
        }
      })
    );
  }

  public changePassword(
    data: ChangePasswordInterface
  ): Observable<CallResponceInterface> {
    this.spinner.show();

    return this.authApiService.apiChangePassword(data).pipe(
      tap(({ error }: CallResponceInterface) => {
        this.spinner.hide();
        if (!error) {
          this.notificationsService.success(
            "common.notifications",
            "password",
            "was_successfully_changed"
          );
        }
      })
    );
  }

  public getConfirmationCode(link: string): Observable<any> {
    this.spinner.show();
    return this.authApiService.apiConfirmationCode(link).pipe(
      tap(() => {
        this.spinner.hide();
      }),
      map((data) => {
        if (data.error) {
          this.router.navigate(["/forgot-password"]);
        }
        return data.data;
      })
    );
  }

  public logOut(): void {
    this.signOutSubject.next(true);
  }

  public refreshAccessToken(): Observable<Object> {
    return this.authApiService.refreshAccessToken(this.getRefreshToken());
  }

  public saveToken(res): boolean {
    try {
      this.tokenService.saveTokenFromResponse(res);
    } catch (e) {
      return false;
    }
    return true;
  }

  public setWebSessionId(): boolean {
    try {
      this._sessionId = uuidv4();
    } catch (e) {
      return false;
    }
    return true;
  }

  public setDeviceUuid() {
    try {
      this._deviceId = new DeviceUUID().get();
    } catch (e) {
      return false;
    }
    return true;
  }

  public isAuthenticated(): boolean {
    return this.tokenService.isTokenStored();
  }

  public getAccessToken(): string | null {
    const token: TokenInterface | null = this.tokenService.getToken();
    return token ? token.accessToken : null;
  }

  public getRefreshToken(): string | null {
    const token: TokenInterface | null = this.tokenService.getToken();
    return token ? token.refreshToken : null;
  }

  public isAdminOrRootUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return (
      decodedToken &&
      (decodedToken.roleName === Roles.Admin ||
        decodedToken.roleName === Roles.Root)
    );
  }

  public isRootUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return decodedToken && decodedToken.roleName === Roles.Root;
  }

  public isIndividualUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return decodedToken && decodedToken.roleName === Roles.Client;
  }

  public isBusinessUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return (
      decodedToken &&
      (decodedToken.roleName === Roles.BusinessEnterprise ||
        decodedToken.roleName === Roles.BusinessCorporate)
    );
  }

  public isBusinessEnterpriseUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return decodedToken && decodedToken.roleName === Roles.BusinessEnterprise;
  }

  public isBusinessCorporateUser(): boolean {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return decodedToken && decodedToken.roleName === Roles.BusinessCorporate;
  }

  public getUserRole(): Roles {
    return this.decodeToken(this.getAccessToken()).roleName as Roles;
  }

  public getUserEmail(): string {
    return this.decodeToken(this.getAccessToken()).email;
  }

  public getFullName(): string {
    if (!this.currentUserFullName) {
      const decodedToken = this.decodeToken(this.getAccessToken());
      return decodedToken
        ? `${decodedToken.firstName} ${decodedToken.lastName}`
        : "";
    }

    return this.currentUserFullName;
  }

  public currentUserId(): string | null {
    const decodedToken = this.decodeToken(this.getAccessToken());
    // eslint-disable-next-line no-nested-ternary
    return decodedToken ? (decodedToken.uid ? decodedToken.uid : null) : null;
  }

  public getCurrentUsername(): string {
    const decodedToken = this.decodeToken(this.getAccessToken());
    return decodedToken ? decodedToken.username : "";
  }

  public checkFullNameUpdate(
    isMyProfile: boolean,
    profile: ProfileModel,
    updatedProfile: ProfileModel
  ): void {
    if (
      isMyProfile &&
      (profile.firstName !== updatedProfile.firstName ||
        profile.lastName !== updatedProfile.lastName)
    ) {
      this.updateCurrentUserFullName(
        `${updatedProfile.firstName} ${updatedProfile.lastName}`
      );
    }
  }

  public getCurrentCountryFromExternalApi(): Observable<CountryFromApi> {
    return this.authApiService
      .apiGetCurrentCountryFromExternal()
      .pipe(catchError(() => of(null)));
  }

  public getInfoAboutCurrentUserByTempToken(): Observable<LimitedUserInfoInterface> {
    return this.authApiService
      .getInfoAboutCurrentUserByTempToken()
      .pipe(map(({ data }) => data as LimitedUserInfoInterface));
  }

  private updateCurrentUserFullName(fullName: string): void {
    this.currentUserFullNameSubject.next(fullName);
    this.currentUserFullName = fullName;

    this.refreshAccessToken().subscribe((res: any) => {
      if (!this.saveToken(res)) {
        this.logOut();
      }
    });
  }

  private decodeToken(token: string): { [key: string]: string } {
    return this.helper.decodeToken(token);
  }

  private logoutFromSystem(dispatch = false) {
    this.tokenService.removeToken();
    this.appOptionService.clearOptions();
    if (dispatch) {
      this.store.dispatch(new LogoutActions.LogOut(false));
    }
  }

  private subscribeToSignOut() {
    this.signOutSubject
      .asObservable()
      .pipe(
        filter(() => this.isAuthenticated()),
        tap(() => this.spinner.show()),
        exhaustMap((dispath: boolean) =>
          this.authApiService.logout().pipe(
            catchError(() => of(null)),
            take(1),
            tap(() => {
              this.profileService.resetProfile();
              this.logoutFromSystem(dispath);
              this.spinner.hide();
            })
          )
        )
      )
      .subscribe();
  }
}
